import { environment } from "../../../environments/environment";
import { HttpInterceptor } from "../http/http.interceptor";
import { BaseModel } from "../interfaces/base.model";
import { RequestOptions } from "../interfaces/request-options.interface";

export abstract class BaseService<
	T extends BaseModel,
	O extends RequestOptions<T>
> {
	constructor(
		protected http: HttpInterceptor,
		protected collection: string
	) {}

	public buildUrl(
		options: O,
		suffix?: string,
		environmentV2Url?: boolean,
		isCompensation?: boolean
	): string {
		const getParams: Dict<any> = options.getParams || {};

		if (options.approvalTypes) {
			getParams["approvalTypes"] = options.approvalTypes;
		}

		if (options.process) {
			getParams["process"] = options.process;
		}

		if (options.userId) {
			getParams["userId"] = options.userId;
		}

		if (options.processType) {
			getParams["processType"] = options.processType;
		}

		if (options.search) {
			getParams["search"] = options.search;
		}

		if (options.username) {
			getParams["username"] = options.username;
		}

		if (options.filterBy) {
			getParams["filter"] = options.filterBy;
		}

		if (options.size) {
			getParams["size"] = options.size;
		}

		if (options.page != null) {
			getParams["page"] = options.page;
		}

		if (options.sort) {
			let x = options.sort;
			let dir = x.dir ? x.dir : "asc";
			getParams["sort"] = [x.field, dir];
		}

		if (options.like !== undefined) {
			getParams["like"] = options.like;
		}

		if (options.searchTerm !== undefined) {
			getParams["search"] = options.searchTerm;
		}

		if (options.filter && options.filter.val) {
			getParams["sFields"] = options.filter.fields;
			getParams["sVal"] = options.filter.val;
		}

		if (options.include) {
			getParams["include"] = options.include;
		}

		if (options.expand) {
			getParams["expand"] = options.expand;
		}
		if (options.executionStatus) {
			getParams["executionStatus"] = options.executionStatus;
		}

		let url = isCompensation
			? this.buildUri(options)
			: (environmentV2Url ? environment.apiV2Url : environment.apiUrl) +
			  this.buildUri(options);
		// let url = (environmentV2Url ? environment.apiV2Url : environment.apiUrl) + this.buildUri(options);

		if (suffix) {
			url += suffix;
		}

		const params = this.buildGetParams(getParams);
		if (params) {
			url += "?" + params;
		}

		return url;
	}

	public getUri(): string {
		return environment.apiUrl + this.collection;
	}

	protected buildUri(options: O): string {
		let url = this.collection;

		if (options.ignoreUrlBuild) {
			return url;
		}

		if (options.id) {
			url += "/" + options.id;
		} else if (options.data && options.data.id) {
			url += "/" + options.data.id;
		}

		return url;
	}

	protected buildGetParams(params: Dict<any>): string {
		let result = "";

		for (let prop in params) {
			if (params.hasOwnProperty(prop)) {
				if (result) {
					result += "&";
				}

				if (Array.isArray(params[prop])) {
					result +=
						encodeURIComponent(prop) +
						"=" +
						(<Array<string>>params[prop])
							.map((x) => encodeURIComponent(x))
							.join(",");
				} else {
					result +=
						encodeURIComponent(prop) +
						"=" +
						encodeURIComponent(params[prop]);
				}
			}
		}

		return result;
	}

	protected handleError(msg: string): void {
		if (!environment.production) {
			alert(msg);
		} else {
			throw new Error(msg);
		}
	}
}
